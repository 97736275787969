import React from 'react';
import { withRouter } from "react-router-dom";
import Header from '../header/header';

const ErrorPage = ({ history, ...rest }) => {
    let data = {};
    if (rest.location && rest.location.state){
        data = { ...rest.location.state};
    }
    const { buttonText='', firstText='', secondText='', route='/' } = data;
    const actionButton = () => {
        // history.push(route);
        console.log("route",route)
        window.location.href='https://www.bajajcapitalone.com/mutual-fund';
    };
    return (
        <Header>
            <section className="aboutbanner errorpage pt40">
                <img src={require(`../../assets/images/${(window.innerWidth <= 575) ? '404-error-m.svg' : '404-error.svg' }`)} 
                    alt="404_error" className="img-fluid"  />
                <div className="container">
                    <div className="row">
                    <div className="col-md-8 offset-md-2 sec-heading text-center pt100">
                            {firstText || `Seems like you're lost.`}<h1>  {secondText || `Let's take you back`} </h1></div>
                        <div className="col-md-8 offset-md-2  text-center">
                            <button onClick={actionButton} type="button" className="btn btn-primary active">{buttonText || 'HOME'}</button>
                        </div>
                    </div>
                </div>
            </section>

        </Header>
    )
    }

    export default withRouter(ErrorPage);